<template>
  <div
    class="progress-spinner"
    :style="{ width: `${width}px`, height: `${height}px` }"
    :class="getSpinnerColor"
    aria-label="Loading"
  >
    <svg class="progress-spinner__svg" viewBox="25 25 50 50">
      <circle
        class="progress-spinner__circle"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="4"
        strokemiterlimit="10"
      ></circle>
    </svg>
  </div>
</template>

<script lang="ts" setup>
import type { ProgressSpinnerProps } from '~/types/ui'

const props = withDefaults(defineProps<ProgressSpinnerProps>(), {
  color: 'current',
  width: '25',
  height: '25',
})

const getSpinnerColor = computed(() => (props.color ? `progress-spinner-${props.color}` : 'progress-spinner-current'))
</script>

<style scoped lang="scss">
@import '@/styles/variables.scss';

@keyframes progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.progress-spinner {
  &__svg {
    animation: progress-spinner-rotate 2s linear infinite;
  }
}

$colors: (
  current: currentColor,
  white: white,
  black: $black-100,
  gold: $gold-primary-100,
);

@each $key, $value in $colors {
  // Generate unique animation with current color
  @keyframes progress-spinner-#{'' + $key} {
    100%,
    0% {
      stroke: $value;
    }
  }
  .progress-spinner-#{'' + $key} {
    .progress-spinner__circle {
      animation:
        progress-spinner-dash 1.5s ease-in-out infinite,
        progress-spinner-#{'' + $key} 6s ease-in-out infinite;
      stroke: $value;
    }
  }
}
</style>
